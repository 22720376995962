import { Order } from '..//atoms/order.atom'

export const useApi = () => {
  const order = async (ord: Order & { sponsorshipId: string | null }) => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/stripe/session`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            package: ord.packageId,
            sub: ord.sub,
            backgrounds: ord.backgrounds,
            options: ord.options,
            calendlyUri: ord.calendlyUri,
            allowShareImages: ord.allowShareImages,
            discountCode: ord.discountCode.value,
            sponsorshipId: ord.sponsorshipId,
          }),
        }
      )

      const json = await response.json()

      if (!response.ok) {
        throw new Error(json?.message ?? "Une s'est produite")
      }

      return json
    } catch (error) {
      throw error
    }
  }

  const useDiscountCode = async (code: string) => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/stripe/promotion-code?code=${code}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      return await response.json()
    } catch (error) {
    }
  }

  const useSponsorshipCode = async (code: string) => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/reservation/sponsorship?code=${code}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      return await response.json()
    } catch (error) {
    }
  }

  const validateSponsorship = async (code: string) => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/reservation/sponsorship`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            code,
          }),
        }
      )

      return await response.json()
    } catch (error) {
    }
  }
  const sendEmail = async (formData: { name: string, email: string, phone: string, message: string }) => {
    try {
      const response = await fetch(`${process.env.GATSBY_API_URL}/v1/sendMail/send-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })

      if (!response.ok) {
        const json = await response.json()
        throw new Error(json?.message ?? "An error occurred")
      }

      return await response.json()
    } catch (error) {
      throw error
    }
  }

  return { order, useDiscountCode, useSponsorshipCode, validateSponsorship, sendEmail }
}
