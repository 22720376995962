import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

export default function Pargh({
  file,
  className,
}: {
  file: string
  className: string
}) {
  const data = useStaticQuery(graphql`
    query {
      allMdx {
        nodes {
          body
          internal {
            contentFilePath
            description
          }
        }
      }
    }
  `)
  const mdxNode = data.allMdx.nodes.find(
    (node: { internal: { contentFilePath: string | string[] } }) =>
      node.internal.contentFilePath.includes(file)
  )
  if (!mdxNode) {
    return <p>File not found</p>
  }

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: mdxNode.body }}
    />
  )
}
