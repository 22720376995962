import React, { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react'
import { css, cx } from '../../../styled-system/css'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> { }

const inputStyle = css({
  p: '10px 5px',
  rounded: '5px',
  fontSize: '13px',
  outline: 'none',
  border: '1px solid grey',
})

export const Input = forwardRef(
  (props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { className, ...rest } = props

    return <input ref={ref} className={cx(inputStyle, className)} {...rest} />
  }
)
