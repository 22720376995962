import React, { ReactNode, useState } from 'react'
import { Typography } from './Typography'
import { css, cx } from '../../../styled-system/css'
import Heart from '../../images/Heart.svg'
import HeartFilled from '../../images/HeartFilled.svg'
import { getFeedBacks } from '../../types/photo'
import Pargh from './Pargh'

export const Feedbacks = ({
  className,
}: {
  className?: string
  seanceType?: string
}) => {
  return (
    <div
      className={cx(
        className,
        css({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '30px',
          padding: '20px',
        })
      )}
    >
      <Typography
        className={css({
          color: '#19213D',
          fontWeight: '500',
          textAlign: 'center',
          fontSize: '2rem',
          '@media (max-width: 768px)': {
            fontSize: '1.5rem',
          },
        })}
        as="h2"
      >
        Ils ont aimé leur séance photo Grossesse avec Digit Universe
      </Typography>
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '20px',
          width: '100%',
          '@media (max-width: 1200px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
          '@media (max-width: 768px)': {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <Feedback />
        <Feedback heartFill={true} />
        <Feedback />
        <Feedback heartFill={true} />
      </div>
    </div>
  )
}

export const Feedbacks_title = ({
  className,
  title,
  id,
}: {
  className?: string
  title?: string
  id?: string
}) => {
  let feedbacks = null
  if (id) {
    feedbacks = getFeedBacks(id)
  }
  const [current, setCurrent] = useState(0)
  function goNext() {
    setCurrent((prevCurrent) => (prevCurrent + 1) % feedbacks.length)
  }

  function goPrev() {
    setCurrent(
      (prevCurrent) => (prevCurrent - 1 + feedbacks.length) % feedbacks.length
    )
  }

  return (
    <div
      className={cx(
        className,
        css({
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          padding: '20px',
          width: '100%',
        })
      )}
    >
      <Typography
        className={css({
          color: '#19213D',
          fontWeight: '500',
          textAlign: 'center',
          fontSize: '2rem',
          '@media (max-width: 768px)': {
            fontSize: '1.5rem',
          },
        })}
        as="h2"
      >
        {title}
      </Typography>
      {/*---------------------------- desktop --------------------------------------*/}
      <div
        className={css({
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: '20px',
          width: '100%',
          '@media (max-width: 1200px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
          '@media (max-width: 768px)': {
            display: 'none',
          },
        })}
      >
        {feedbacks ? (
          feedbacks.map((feedback, index) => (
            <Feedback
              key={index}
              heartFill={index % 2 !== 0}
              name={feedback.name}
            >
              <Pargh className={''} file={feedback.text} />
            </Feedback>
          ))
        ) : (
          <>
            <Feedback />
            <Feedback heartFill={true} />
            <Feedback />
            <Feedback heartFill={true} />
          </>
        )}
      </div>
      {/*---------------------------- Mob--------------------------------------*/}

      <div
        className={css({
          width: '100%',
          position: 'relative',
          display: 'none',
          '@media (max-width: 768px)': {
            display: 'block',
          },
        })}
      >
        {feedbacks ? (
          <Feedback
            heartFill={current % 2 !== 0}
            name={feedbacks[current].name}
          >
            <Pargh className={''} file={feedbacks[current].text} />
          </Feedback>
        ) : (
          <Feedback heartFill={current % 2 !== 0}></Feedback>
        )}
        {/*prev button */}
        <button
          className={css({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            color: 'white',
            border: 'none',
            padding: '10px',
            cursor: 'pointer',
            zIndex: '10',
            fontSize: '30px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          })}
          onClick={goPrev}
        >
          &#10094;
        </button>
        {/*Next button */}
        <button
          className={css({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            color: 'white',
            border: 'none',
            padding: '10px',
            cursor: 'pointer',
            fontSize: '30px',
            zIndex: '10',
            right: '0',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          })}
          onClick={goNext}
        >
          &#10095;
        </button>
      </div>
    </div>
  )
}

export const Feedback = ({
  name,
  heartFill,
  children,
}: {
  heartFill?: boolean
  name?: string
  children?: ReactNode
}) => {
  const text =
    "Ma séance photo de grossesse avec Digit Universe a été une expérience incroyable ! L'équipe était professionnelle, patiente et incroyablement créative, rendant le processus à la fois confortable et amusant. Les photos sont magnifiques, capturant parfaitement cette période spéciale de ma vie. Je suis tellement heureuse d'avoir ces souvenirs à chérir pour toujours. Je recommande vivement Digit Universe à toutes les futures mamans cherchant à immortaliser leur grossesse."
  return (
    <div
      className={css({
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        minWidth: '300px',
        backgroundColor: heartFill ? '#ffffff' : '#f5f5f5',
        boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
        width: '100%',
        boxSizing: 'border-box',
      })}
    >
      {heartFill ? (
        <img
          className={css({ alignSelf: 'center' })}
          src={HeartFilled}
          alt="HeartFilled"
        />
      ) : (
        <img className={css({ alignSelf: 'center' })} src={Heart} alt="Heart" />
      )}
      <div className={css({ fontSize: '14px', flexGrow: '1' })}>
        {children ? children : text}
      </div>
      <Typography
        className={css({
          textAlign: 'center',
          fontSize: '14px !important',
          fontWeight: 'bold',
          width: '100%',
        })}
        as="span"
      >
        {name ? name : 'Iréne & Loic - Séance photo grossesse'}
      </Typography>
    </div>
  )
}

export const Feedbacks_photos = ({
  className,
  title,
  seanceType,
}: {
  className?: string
  title?: string
  seanceType?: string
}) => {
  let feedbacks = null
  feedbacks = [
    {
      name: 'Cristina et Christophe - Reportage photo baptême',
      text: 'Bapteme_feed1.mdx',
      category: 'baptême',
    },
    {
      name: 'Erica et Anthony - Reportage photo baptême',
      text: 'Bapteme_feed2.mdx',
      category: 'baptême',
    },
    {
      name: 'Irène et Loïc - Reportage photo baptême',
      text: 'Bapteme_feed3.mdx',
      category: 'baptême',
    },
    {
      name: 'Alexandre et Marie - Reportage photo baptême',
      text: 'Bapteme_feed4.mdx',
      category: 'baptême',
    },

    {
      name: 'Iréne & Loic - Séance photo bébé',
      text: 'Bebe_feed1.mdx',
      category: 'bébé',
    },
    {
      name: 'Marie & Luigi - Séance photo bébé',
      text: 'Bebe_feed2.mdx',
      category: 'bébé',
    },
    {
      name: 'Stella & Thierno - Séance photo bébé',
      text: 'Bebe_feed3.mdx',
      category: 'bébé',
    },
    {
      name: 'Radojika & Paul - Séance photo bébé',
      text: 'Bebe_feed4.mdx',
      category: 'bébé',
    },

    {
      name: 'Iréne & Loic - Séance photo couple',
      text: 'couple_feed1.mdx',
      category: 'couple',
    },
    {
      name: 'Marie & Luigi - Séance photo couple',
      text: 'couple_feed2.mdx',
      category: 'couple',
    },
    {
      name: 'Stella & Thierno - Séance photo couple',
      text: 'couple_feed3.mdx',
      category: 'couple',
    },
    {
      name: 'Radojika & Paul - Séance photo couple',
      text: 'couple_feed4.mdx',
      category: 'couple',
    },

    {
      name: 'Iréne & Loic - Séance photo grossesse',
      text: 'Gross_feed1.mdx',
      category: 'grossesse',
    },
    {
      name: 'Marie & Luigi - Séance photo grossesse',
      text: 'Gross_feed2.mdx',
      category: 'grossesse',
    },
    {
      name: 'Stella & Thierno - Séance photo grossesse',
      text: 'Gross_feed3.mdx',
      category: 'grossesse',
    },
    {
      name: 'Radojika & Paul - Séance photo grossesse',
      text: 'Gross_feed4.mdx',
      category: 'grossesse',
    },

    {
      name: 'Iréne & Loic - Séance photo halloween',
      text: 'halloween_feed1.mdx',
      category: 'halloween',
    },
    {
      name: 'Marie & Luigi - Séance photo halloween',
      text: 'halloween_feed2.mdx',
      category: 'halloween',
    },
    {
      name: 'Stella & Thierno - Séance photo halloween',
      text: 'halloween_feed3.mdx',
      category: 'halloween',
    },
    {
      name: 'Radojika & Paul - Séance photo halloween',
      text: 'halloween_feed4.mdx',
      category: 'halloween',
    },

    {
      name: 'Iréne & Loic - Séance photo naissance',
      text: 'Naissance_feed1.mdx',
      category: 'naissance',
    },
    {
      name: 'Marie & Luigi - Séance photo naissance',
      text: 'Naissance_feed2.mdx',
      category: 'naissance',
    },
    {
      name: 'Stella & Thierno - Séance photo naissance',
      text: 'Naissance_feed3.mdx',
      category: 'naissance',
    },
    {
      name: 'Radojika & Paul - Séance photo naissance',
      text: 'Naissance_feed4.mdx',
      category: 'naissance',
    },

    {
      name: 'Iréne & Loic - Séance photo noel',
      text: 'noel_feed1.mdx',
      category: 'noel',
    },
    {
      name: 'Marie & Luigi - Séance photo noel',
      text: 'noel_feed2.mdx',
      category: 'noel',
    },
    {
      name: 'Stella & Thierno - Séance photo noel',
      text: 'noel_feed3.mdx',
      category: 'noel',
    },
    {
      name: 'Radojika & Paul - Séance photo noel',
      text: 'noel_feed4.mdx',
      category: 'noel',
    },

    {
      name: 'Iréne & Loic - Séance photo paques',
      text: 'paque_feed1.mdx',
      category: 'paques',
    },
    {
      name: 'Marie & Luigi - Séance photo paques',
      text: 'paque_feed2.mdx',
      category: 'paques',
    },
    {
      name: 'Stella & Thierno - Séance photo paques',
      text: 'paque_feed3.mdx',
      category: 'paques',
    },
    {
      name: 'Radojika & Paul - Séance photo paques',
      text: 'paque_feed4.mdx',
      category: 'paques',
    },

    {
      name: 'Iréne & Loic - Séance photo pro',
      text: 'pro_feed1.mdx',
      category: 'pro',
    },
    {
      name: 'Marie - Séance photo pro',
      text: 'pro_feed2.mdx',
      category: 'pro',
    },
    {
      name: 'Stella & Thierno - Séance photo pro',
      text: 'pro_feed3.mdx',
      category: 'pro',
    },
    {
      name: 'Radojika & Paul - Séance photo pro',
      text: 'pro_feed4.mdx',
      category: 'pro',
    },
  ]

  if (seanceType) {
    feedbacks = feedbacks.filter(
      (feedback) => feedback.category === seanceType?.toLocaleLowerCase()
    )
  } else {
    feedbacks.sort(() => Math.random() - 0.5)
  }

  feedbacks = feedbacks.slice(0, 4)

  if (feedbacks.length === 0) {
    return null
  }
  const [current, setCurrent] = useState(0)
  function goNext() {
    setCurrent((prevCurrent) => (prevCurrent + 1) % feedbacks.length)
  }

  function goPrev() {
    setCurrent(
      (prevCurrent) => (prevCurrent - 1 + feedbacks.length) % feedbacks.length
    )
  }

  return (
    <>
      <div
        className={css({
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1.25rem',
          width: '100%',
          '& > div': {
            flex: '0.25 0.25 0%',
            height: '23rem',
          },
          xlDown: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
          lgDown: {
            display: 'none',
          },
        })}
      >
        {feedbacks ? (
          feedbacks.map((feedback, index) => (
            <Feedback
              key={index}
              heartFill={index % 2 !== 0}
              name={feedback.name}
            >
              <Pargh className={''} file={feedback.text} />
            </Feedback>
          ))
        ) : (
          <>
            <Feedback />
            <Feedback heartFill={true} />
            <Feedback />
            <Feedback heartFill={true} />
          </>
        )}
      </div>
      {/*---------------------------- Mob--------------------------------------*/}

      <div
        className={css({
          width: '100%',
          position: 'relative',
          display: 'none',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '1rem',
          lgDown: {
            display: 'flex',
          },
          '& > div': {
            height: '25rem',
          },
        })}
      >
        <button
          className={css({
            color: '#D9C58B',
            border: 'none',
            cursor: 'pointer',
            fontSize: '2.5rem',
          })}
          onClick={goPrev}
        >
          &#10094;
        </button>

        {feedbacks ? (
          <Feedback
            heartFill={current % 2 !== 0}
            name={feedbacks[current].name}
          >
            <Pargh className={''} file={feedbacks[current].text} />
          </Feedback>
        ) : (
          <Feedback heartFill={current % 2 !== 0}></Feedback>
        )}

        <button
          className={css({
            color: '#D9C58B',
            border: 'none',
            cursor: 'pointer',
            fontSize: '2.5rem',
          })}
          onClick={goNext}
        >
          &#10095;
        </button>
      </div>
    </>
  )
}
