import React, { useEffect } from 'react'
import { useSetOrder } from '../atoms/order.atom'
import { Pricing, PricingCard } from '../components/photographie/Pricing'
import { Typography } from '../components/photographie/Typography'
import { Package } from '@dup/products'
import { css } from '../../styled-system/css'
import { CARDS_COLORS } from '../consts'
import { Button } from '../components/photographie/Button'
import { scrollToTop } from '../utils/scrollToTop'

export const OptionsSelection = (props: {
  pkg: Package
  back: () => void
  next: () => void
}) => {
  const { pkg, back, next } = props
  const setOrder = useSetOrder()

  const cards: PricingCard[] = pkg.options.map((_option, idx) => {
    const colorIndex = idx % 3

    return {
      id: _option.id,
      label: _option.label,
      price: {
        color: '#D9C58B',
        value: _option.price.value,
        label: _option.price.label,
        labelColor: _option.price.color,
      },
      bgColor: CARDS_COLORS[colorIndex],
    }
  })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (value: any) => {
    setOrder((prevValue) => {
      return { ...prevValue, options: value }
    })
  }

  useEffect(scrollToTop, [])

  return (
    <>
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          gap: '3.125rem',
          flexWrap: 'wrap',
          flexDir: 'column',
          textAlign: 'center',
          lgDown: {
            gap: 0,
          }
        })}
      >
        <div
          className={css({
            flex: 0.5,
            display: 'flex',
            flexDir: 'column',
            gap: '1rem',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            lgDown: {
              width: '100%',
            }
          })}
        >
          <Typography
            className={css({ color: 'fonts.darkBlue', textAlign: 'center' })}
            as="h1"
          >
            Sélectionnez vos options
          </Typography>
          <Typography className={css({ color: 'fonts.greyBlue' })} as="span">
            Les tarifs comprennent la prise de vue, les retouches et les frais
            divers photographiques(serveurs hébergement photos, abonnements
            logiciels retouche, fiscalité, abonnements aux sites partenaires
            produits photos et galeries, accessoires, etc.)
          </Typography>

          <Pricing
            className={css({ mt: '30px' })}
            onChangeCard={handleChange}
            cards={cards}
            multiple={true}
          />

          <div
            className={css({
              display: 'flex',
              justifyContent: 'space-between',
              width: "70%",
              mt: '20px',
            })}
          >
            <Button
              onClick={back}
              variants={{ format: 'normal', visual: 'secondary' }}
            >
              Retour
            </Button>
            <Button
              onClick={next}
              variants={{ format: 'normal', visual: 'digit' }}
            >
              JE VALIDE
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
