import React from 'react'
import { Typography } from './Typography'
import { Image } from './Image'
import { css } from '../../../styled-system/css'
import { CheckCircle } from 'lucide-react'
import { BackgroundManagementProps } from '../../types/backgrounds.manage'
import { Background } from '@dup/products'

type BackgroundsSelectionProps = BackgroundManagementProps

export const BackgroundsSelection = ({
  themes,
  selectedThemes,
  setSelectedThemes,
}: BackgroundsSelectionProps) => {
  const handleClick = (id: string) => {
    setSelectedThemes((prevV) => prevV.includes(id) ? prevV.filter((themeId) => themeId !== id) : [...prevV, id]);
  }

  const formatedThemes = themes.reduce(
    (pre: { category: string; items: Background[] }[], next) => {
      const category = pre.find((v) => v.category === next.category)
      if (category) {
        category.items.push(next)
        return pre
      } else {
        return [...pre, { category: next.category, items: [next] }]
      }
    },
    []
  )

  return (
    <div className={css({ display: 'flex', flexDir: 'column', gap: '2rem' })}>
      {formatedThemes.map((cat) => (
        <div
          key={cat.category}
          className={css({ display: 'flex', flexDir: 'column', gap: '0.25rem' })}
        >
          {cat.category ? (
            <Typography as="h4">Séance Photo {cat.category}</Typography>
          ) : null}
          <div
            className={css({
              display: 'grid',
              gap: '.25rem',
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
              lgDown: {
                gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              },

              mdDown: {
                gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
              },
            })}
          >
            {cat.items.map((theme, idx) => {
              const selected = selectedThemes.includes(theme.id)

              return (
                <div
                  className={css({
                    flex: '1',
                    height: '17.875rem',
                    cursor: 'pointer',
                    pos: 'relative',
                    transition: '.3s',
                  })}
                  key={idx}
                  onClick={() => handleClick(theme.id)}
                  style={
                    selected
                      ? { border: '0.125rem solid #594A2E' }
                      : { border: '0.125rem solid transparent' }
                  }
                >
                  <Image
                    className={css({
                      h: '100%',
                      w: '100%',
                      objectFit: 'cover',
                      transition: '.3s',
                    })}
                    relativePath={`themes/${theme.image}`}
                    style={selected ? { filter: 'blur(0.188rem)' } : {}}
                  />
                  {selected ? (
                    <CheckCircle
                      className={css({
                        pos: 'absolute',
                        translate: '-50% -50%',
                        left: '50%',
                        top: '50%',
                        color: '#594A2E',
                      })}
                      size={100}
                    />
                  ) : null}

                  <div
                    className={css({
                      pos: 'absolute',
                      bottom: '0',
                      bg: 'rgba(255, 255, 255, .4)',
                      height: '2.5rem',
                      w: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    })}
                  >
                    <Typography as="span">{theme.label}</Typography>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
}
