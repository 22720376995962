/* eslint-disable react/no-unescaped-entities */
import { Typography } from '../components/photographie/Typography'
import { css } from '../../styled-system/css'
import { Checkbox } from '../components/photographie/Checkbox'
import React, { ChangeEvent, useRef, useState } from 'react'
import { useOrderValue, useSetOrder } from '../atoms/order.atom'
import { Button } from '../components/photographie/Button'
import { useApi } from '../hooks/useApi'
import { Input } from '../components/photographie/Input'
import { packages } from '@dup/products'
import { Image } from '../components/photographie/Image'

export const Summary = () => {
  const setOrder = useSetOrder()
  const discountInput = useRef<HTMLInputElement>(null)
  const { useDiscountCode } = useApi()
  const [visible, setVisible] = useState(false)

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setOrder((_prevV) => {
      return {
        ..._prevV,
        allowShareImages: e.target.checked,
      }
    })
  }

  const handleUseDiscountCode = async () => {
    if (
      discountInput.current === null ||
      discountInput.current.value.trim().length === 0
    ) {
      return
    }

    const code = await useDiscountCode(discountInput.current.value)

    if (!code.valid) {
      setOrder((prevV) => {
        return {
          ...prevV,
          discountCode: {
            label: '',
            value: null,
            amount: 0,
          },
        }
      })
      return
    }

    setOrder((prevV) => {
      return {
        ...prevV,
        discountCode: {
          label: code.name,
          value: discountInput.current!.value,
          amount: code.amount_off,
        },
      }
    })
  }

  return (
    <div
      className={css({
        mb: '30px',
        margin: '0 10%',
        mdDown: {
          margin: '0 1%',
        },
        '& h3': {
          fontWeight: '500 !important',
          fontSize: 'clamp(1rem, -1.5rem + 8vw, 1.4rem) !important',
        },
      })}
    >
      <Typography className={css({ color: 'fonts.darkBlue' })} as="h1">
        Votre réservation
      </Typography>

      <Typography className={css({ color: 'fonts.darkBlue' })} as="span">
        En acceptant, vous consentez à l'utilisation et à la diffusion des
        photos prises lors de la séance photo pour des fins promotionnelles.{' '}
      </Typography>
      <Checkbox onChange={onChange}>
        Accepter{' '}
        <Typography
          className={css({ cursor: 'pointer', color: 'blue' })}
          onClick={() => setVisible((prevV) => !prevV)}
          id="not-clickable"
          as="span"
        >
          (?)
        </Typography>
      </Checkbox>
      {visible && (
        <div
          className={css({
            fontStyle: 'italic',
            bg: 'rgba(0, 0, 0, .2)',
            p: '8px',
            rounded: '5px',
            color: 'blue',
          })}
        >
          Je reconnais et consens par la présente à autoriser l'utilisation, la
          publication et la diffusion des photographies prises lors de la séance
          photo à laquelle J'ai participé. Je comprends que ces photographies
          peuvent être utilisées à des fins commerciales, promotionnelles,
          publicitaires ou éducatives, notamment sur des sites web, des réseaux
          sociaux, des supports imprimés ou tout autre média, dans le cadre de
          la promotion des produits ou services. J'accorde cette autorisation
          sans aucune rémunération ou compensation financière présente ou
          future. Je certifie également que j'ai le droit de donner cette
          autorisation et que je libère par la présente le photographe ou toute
          partie associée à la séance photo de toute réclamation ou
          responsabilité en relation avec l'utilisation des photographies.
        </div>
      )}
      <div
        className={css({
          mt: '20px',
          display: 'flex',
          flexDir: 'column',
          gap: '10px',
        })}
      >
        <Typography
          className={css({ color: 'fonts.darkBlue', fontWeight: 600 })}
          as="h6"
        >
          Utiliser un code de réduction
        </Typography>

        <div className={css({ display: 'flex', gap: '10px' })}>
          <Input
            ref={discountInput}
            placeholder="Code de réduction"
            type="text"
          />
          <Button
            onClick={handleUseDiscountCode}
            variants={{ format: 'normal', visual: 'secondary' }}
          >
            Utiliser
          </Button>
        </div>
      </div>

      <OrderResume />
    </div>
  )
}

const OrderResume = () => {
  const order = useOrderValue()

  const pkg = packages.find((pk) => pk.id === order.packageId)

  if (!pkg) {
    return null
  }

  const sub = pkg.subs.find((sub) => sub.id === order.sub)

  const options = pkg.options.filter((opt) => order.options.includes(opt.id))

  const backgrounds = pkg.backgrounds.filter((bg) =>
    order.backgrounds.includes(bg.id)
  )

  const total =
    backgrounds.reduce((a, b) => a + b.price.value, 0) +
    options.reduce((a, b) => a + b.price.value, 0) +
    (sub?.price ?? 0)

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        mt: '50px',
      })}
    >
      <Typography
        className={css({ textAlign: 'center', color: '#19213D !important' })}
        as="h2"
      >
        Vous réservez pour séance photo {pkg.label}
      </Typography>
      <div className={css({ display: 'flex', flexDir: 'column', gap: '10px' })}>
        <div
          className={css({
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          })}
        >
          <Typography as="h3">{sub?.label}</Typography>
          <Typography as="h3">{(sub?.price ?? 0) / 100}€</Typography>
        </div>

        {options.length ? (
          <div>
            <Typography as="h3">Options</Typography>
            <div className={css({ ml: '25px' })}>
              {options.map((option) => (
                <div
                  className={css({
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  })}
                  key={option.id}
                >
                  <Typography as="h3">{option.label}</Typography>
                  <Typography as="h3">{option.price.value / 100}€</Typography>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {backgrounds.length ? (
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <div
              className={css({
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              })}
            >
              <Typography as="h3">Vos fonds: </Typography>
              {backgrounds.map((background) => (
                <Image
                  relativePath={`themes/${background.image}`}
                  key={background.id}
                  className={css({
                    h: '75px',
                    objectFit: 'cover',
                    w: '125px',
                    ml: '10px',
                  })}
                />
              ))}
            </div>
            <Typography as="h3">
              {backgrounds.reduce((a, b) => a + b.price.value, 0) / 100}€
            </Typography>
          </div>
        ) : null}

        {order.allowShareImages ? (
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            })}
          >
            <Typography as="h3">
              Réduction autorisation de publication des photos
            </Typography>

            <Typography as="h3">-20€</Typography>
          </div>
        ) : null}
      </div>

      <hr
        className={css({
          w: '80%',
          alignSelf: 'center',
          m: '50px 0',
          color: '#000000',
        })}
      />

      <Typography className={css({ textAlign: 'end' })} as="h3">
        {total / 100 - (order.allowShareImages ? 20 : 0)}€
      </Typography>
    </div>
  )
}
