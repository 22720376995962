import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  PropsWithChildren,
} from 'react'
import { cva, cx, RecipeVariantProps } from '../../../styled-system/css'

const typo = cva({
  base: {
    fontSize: '1rem',
  },

  variants: {
    as: {
      h1: {
        fontSize: '2rem',
        fontWeight: 700,
      },
      h2: {
        fontSize: '1.8rem',
        fontWeight: 500,
      },
      h3: {
        fontSize: '1.6rem',
        fontWeight: 400,
      },
      h4: {
        fontSize: '1.4rem',
        fontWeight: 300,
      },
      h5: {
        fontSize: '1.2rem',
      },
      h6: {
        fontSize: '1rem',
      },
      p: {
        fontSize: '1rem',
      },
      span: {
        fontSize: '1rem',
      },
    },
  },
})

export type TypographyVariants = RecipeVariantProps<typeof typo>

type TypographyElement = Exclude<
  TypographyVariants extends infer AV
  ? AV extends object
  ? AV[keyof AV & 'as']
  : never
  : never,
  undefined
>

interface TypographyProps {
  as: TypographyElement
  className?: string
  style?: CSSProperties
  id?: string
  onClick?: () => void
}

const Typography = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: PropsWithChildren<TypographyProps>, ref: ForwardedRef<any>) => {
    const { children, as: asElement, className, ...rest } = props

    const Element = asElement

    const csx = cx(typo({ as: asElement }), className)

    return (
      <Element ref={ref} className={csx} {...rest}>
        {children}
      </Element>
    )
  }
)

export { Typography }
