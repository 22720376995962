import React from 'react'
import { cx, css } from '../../../styled-system/css'

interface HrProps {
  className?: string
}

export const Hr = (props: HrProps) => {
  const { className } = props

  return (
    <div
      className={cx(
        css({
          m: '3rem 0%',
          background: '#594A2E',
          height: '.2rem',
          borderRadius: '.2rem',
          alignSelf: 'center',
          w: '50%',
        }),
        className
      )}
    />
  )
}
