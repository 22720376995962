import React, { useEffect } from 'react'
import { HTMLAttributes, useState } from 'react'
import { css, cx } from '../../../styled-system/css'
import { Typography } from './Typography'

type PricingProps = {
  cards: PricingCard[]
  onChangeCard: (value: string | null) => void
  multiple?: boolean
} & HTMLAttributes<HTMLDivElement>

export type PricingCard = {
  label: string
  price: {
    label?: string
    value: number
    color: string
    labelColor?: string
  }
  text?: string
  footer?: {
    value?: string
    color?: string
  }
  id: string
  bgColor?: string
}

export const Pricing = (props: PricingProps) => {
  const { cards, onChangeCard, className, ...rest } = props
  const [currentCard, setCurrentCard] = useState<string | null | string[]>(
    props.multiple ? [] : null
  )

  const handleCardClicked = (_id: string) => {
    if (!props.multiple) {
      if (currentCard === _id) {
        setCurrentCard(null)
        return
      }

      setCurrentCard(_id)
      return
    }

    if (Array.isArray(currentCard)) {
      setCurrentCard((prevV) => {
        const prevVTYped = prevV as unknown as string[]
        if (prevVTYped?.includes(_id)) {
          return prevVTYped.filter((v) => v !== _id)
        }

        return [...prevVTYped, _id]
      })
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeCard(currentCard as unknown as any)
  }, [currentCard])

  return (
    <div
      className={cx(
        css({
          display: 'flex',
          flex: "1",
          flexWrap: 'wrap',
          gap: ".5rem",
          lgDown: {
            width: '90%',
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
          },
        }),
        className
      )
      }
      {...rest}
    >
      {
        cards.map((_card) => (
          <div
            key={_card.id}
            onClick={() => handleCardClicked(_card.id)}
            style={
              Array.isArray(currentCard)
                ? currentCard.includes(_card.id)
                  ? { transform: 'scale(0.95)', backgroundColor: _card.bgColor }
                  : { backgroundColor: _card.bgColor }
                : _card.id === currentCard
                  ? {
                    boxShadow: '0 0 30px 0 #E1E4ED',
                    backgroundColor: _card.bgColor,
                  }
                  : currentCard === null
                    ? { backgroundColor: _card.bgColor }
                    : { transform: 'scale(0.95)', backgroundColor: _card.bgColor }
            }
            className={css({
              flex: 1,
              height: '500px',
              display: 'flex',
              flexDir: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              pos: 'relative',
              minW: '200px',
              gap: '30px',
              textAlign: 'center',
              cursor: 'pointer',
              transition: '.3s',
              border: '1px solid #E1E4ED',
            })}
          >
            <Typography className={css({ maxW: '90%' })} as="h1">
              {_card.label}
            </Typography>
            <Typography className={css({ maxW: '80%', fontWeight: 500 })} as="p">
              {_card.text}
            </Typography>

            <div
              className={css({
                display: 'flex',
                gap: '7.5px',
                '& > div': {
                  height: '40px',
                  w: '10px',
                  bg: '#D9C58B',
                },
              })}
            >
              <div />
              <div />
            </div>
            <div
              className={css({ display: 'flex', flexDir: 'column', gap: '5px' })}
            >
              <Typography style={{ color: _card.price.color }} as="h1">
                {_card.price.value / 100} €
              </Typography>
              <Typography
                style={{ color: _card.price.labelColor, fontWeight: 700 }}
                as="h4"
              >
                {_card.price.label}
              </Typography>
            </div>
            <Typography
              className={css({
                pos: 'absolute',
                bottom: '10px',
                left: '15px',
                fontWeight: 500,
              })}
              as="span"
              style={{ color: _card.footer?.color }}
            >
              {_card.footer?.value}
            </Typography>
          </div>
        ))
      }
    </div >
  )
}
