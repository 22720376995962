import React, { useState } from 'react'
import { Typography } from './Typography'
import { css } from '../../../styled-system/css'
import { ChevronDown } from 'lucide-react'

export const Accordeon = ({ title, body }: { title: string; body: string }) => {
  const [open, setOpen] = useState(false)

  return (
    <div
      className={css({
        position: 'relative',
        display: 'flex',
        flexDir: 'column',
        gap: '10px',
        border: '2px solid #CCCCCC',
        p: '20px',
        bg: '#F5F5F5',
      })}
    >
      <div
        className={css({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
        })}
        onClick={() => setOpen((prevV) => !prevV)}
      >
        <Typography
          className={css({
            color: '#6D758F',
            fontWeight: 'bold',
            userSelect: 'none',
          })}
          as="h5"
        >
          {title}
        </Typography>
        <ChevronDown className={css({
          transition: 'all 0.3s',
        })} style={{ rotate: open ? '180deg' : '0deg' }} />
      </div>

      <div
        className={css({
          color: '#6D758F',
          userSelect: 'none',
          maxHeight: open ? '10rem' : '0',
          transition: 'max-height .3s',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: "1.5rem",
          overflow: 'hidden',
        })}
      >
        <p className={css({
          color: "#6D758F",
          fontWeight: '300',
          fontSize: '1.125rem',
          fontFamily: 'Lato !',
        })}>{body}</p>
      </div>
    </div>
  )
}
