import React, { useEffect } from 'react'
import { useSetOrder } from '../atoms/order.atom'
import { Typography } from '../components/photographie/Typography'
import { InlineWidget, useCalendlyEventListener } from 'react-calendly'
import { css } from '../../styled-system/css'
import { useState } from 'react'
import { Button } from '../components/photographie/Button'
import { scrollToTop } from '../utils/scrollToTop'

const DateAndTimeSelection = ({
  onSelected,
  back,
}: {
  onSelected: () => void
  back: () => void
}) => {
  const setOrder = useSetOrder()
  const [displayCalendar, setDisplayCalendar] = useState(true)
  useEffect(scrollToTop, [])

  const handleEventScheduled = (eventUri: string) => {
    setDisplayCalendar(false)
    setOrder((prevValue) => {
      return { ...prevValue, calendlyUri: eventUri }
    })
    onSelected()
  }

  useCalendlyEventListener({
    onEventScheduled: (e) => handleEventScheduled(e.data.payload.event.uri),
  })

  return (
    <div
      className={css({
        margin: '0 10%',
        mdDown: {
          margin: '0 1%',
        },
        "& > iframe": {
          overflow: "hidden",
        }
      })}
    >
      <Typography className={css({ color: 'fonts.darkBlue', textAlign: "center" })} as="h1">
        Choisissez la date de votre séance photo{' '}
      </Typography>
      {displayCalendar ? (
        <InlineWidget url="https://calendly.com/smailaberkaoui/30min" />
      ) : null}
      <div>
        <Button
          onClick={back}
          variants={{ format: 'normal', visual: 'secondary' }}
        >
          Retour
        </Button>
      </div>
    </div>
  )
}

export { DateAndTimeSelection }
