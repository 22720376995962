import React from 'react'
import { Typography } from './Typography'
import { css, cx } from '../../../styled-system/css'
import { Accordeon } from './Accordeon'

const feedbacks = [
  {
    title: "Comment se passe une séance photo ?",
    body: {
      grossesse: "La séance se déroule dans une ambiance calme, respectant le rythme de la future maman. Je guide à travers des poses naturelles, capturant l'essence unique de votre grossesse.",
      bébé: "La séance se déroule dans un studio chaleureux, respectant le rythme de votre bébé. Je capture des moments tendres et authentiques mettant en valeur son innocence.",
      naissance: "La séance se déroule dans une ambiance calme, mettant en valeur la douceur et l'innocence du nouveau-né. Je capture chaque détail avec soin et créativité.",
      baptême: "Je me rendrais à l'endroit voulu avec mon équipement de photographe pour capturer ces magnifiques moments.",
      couple: "Dans une ambiance détendue, je capture des moments authentiques reflétant votre amour.",
      pro: "Je capture votre profil sous différentes poses, celles qui vous mettent en valeur et reflètent votre professionnalisme.",
      noel: "Inna crée une ambiance chaleureuse, capte des moments naturels et fournit des conseils de pose. Les parents sont présents pour immortaliser l'instant.",
      halloween: "Immersion dans l'univers de Halloween avec poses évocatrices et accessoires thématiques. Chaque instant est capturé avec créativité et soin.",
      paques: "Plongez dans l'ambiance de Pâques avec des décors printaniers et des moments joyeux. Chaque cliché capture la fraîcheur et la vitalité de la saison.",
    }
  },
  {
    title: "Où se déroule la séance photo ?",
    body: {
      grossesse: "Les séances peuvent se dérouler en studio, à domicile ou en extérieur, selon vos préférences et le style que vous souhaitez donner à vos photos. Nous pouvons discuter ensemble du lieu qui vous conviendrait le mieux.",
      bébé: "Les séances peuvent se dérouler en studio, à domicile ou en extérieur, selon vos préférences et le style que vous souhaitez donner à vos photos. Nous pouvons discuter ensemble du lieu qui vous conviendrait le mieux.",
      naissance: "Les séances peuvent se dérouler en studio, à domicile ou en extérieur, selon vos préférences et le style que vous souhaitez donner à vos photos. Nous pouvons discuter ensemble du lieu qui vous conviendrait le mieux.",
      baptême: "La séance se déroulera au lieu de la cérémonie.",
      couple: "Les séances peuvent se dérouler en studio, à domicile ou en extérieur, selon vos préférences et le style que vous souhaitez donner à vos photos. Nous pouvons discuter ensemble du lieu qui vous conviendrait le mieux.",
      pro: "Les séances peuvent se dérouler en studio, à domicile ou en extérieur, selon vos préférences et le style que vous souhaitez donner à vos photos. Nous pouvons discuter ensemble du lieu qui vous conviendrait le mieux.",
      noel: "Les séances peuvent se dérouler en studio, à domicile ou en extérieur, selon vos préférences et le style que vous souhaitez donner à vos photos. Nous pouvons discuter ensemble du lieu qui vous conviendrait le mieux.",
      halloween: "Les séances peuvent se dérouler en studio, à domicile ou en extérieur, selon vos préférences et le style que vous souhaitez donner à vos photos. Nous pouvons discuter ensemble du lieu qui vous conviendrait le mieux.",
      paques: "Les séances peuvent se dérouler en studio, à domicile ou en extérieur, selon vos préférences et le style que vous souhaitez donner à vos photos. Nous pouvons discuter ensemble du lieu qui vous conviendrait le mieux.",
    }
  },
  {
    title: "Combien de temps dure une séance ?",
    body: {
      grossesse: "Une séance photo de grossesse dure généralement entre 1 et 2 heures. Cela nous laisse suffisamment de temps pour varier les poses et les tenues si vous le souhaitez.",
      bébé: "",
      naissance: "",
      baptême: "",
      couple: "",
      pro: "",
      noel: "",
      halloween: "",
      paques: "",
    }
  },
  {
    title: "Que dois-je porter ?",
    body: {
      grossesse: "Choisissez des tenues qui vous mettent à l'aise et mettent en valeur votre ventre. Des robes fluides ou des tops ajustés sont de bons choix. Nous pouvons également fournir des robes de grossesse spécialement conçues pour les séances photo.",
      bébé: "Optez pour des tenues simples et confortables qui reflètent votre style. Nous discuterons en amont pour choisir des vêtements adaptés à la séance.",
      naissance: "Pour les parents, des vêtements simples et confortables. Pour le bébé, des tenues légères et douces.",
      baptême: "Des tenues élégantes et confortables pour la cérémonie, discutées en amont avec vous.",
      couple: "Des tenues qui vous représentent et mettent en valeur votre complicité.",
      pro: "Des tenues formelles et représentatives de votre profession. Nous possédons également des tenues si besoin.",
      noel: "Harmonisez vos tenues pour des photos éclatantes. Aucun appareil photo extérieur n'est autorisé pour une expérience optimale.",
      halloween: "Costumes effrayants ou thématiques. Accessoires bienvenus pour renforcer l'ambiance.",
      paques: "Vêtements printaniers ou costumes de lapin. Accessoires festifs bienvenus pour une ambiance joyeuse. Aucun appareil photo extérieur pour une expérience optimale.",
    }
  },
  {
    title: "Pouvons-nous inclure le père et les frères et sœurs dans la séance ?",
    body: {
      grossesse: "Absolument, n'hésitez pas à inclure des membres de votre famille.",
      bébé: "Absolument, n'hésitez pas à inclure des membres de votre famille.",
      naissance: "Absolument, n'hésitez pas à inclure des membres de votre famille.",
      baptême: "Absolument, n'hésitez pas à inclure des membres de votre famille.",
      couple: "Absolument, n'hésitez pas à inclure des membres de votre famille.",
      pro: "",
      noel: "Absolument, n'hésitez pas à inclure des membres de votre famille.",
      halloween: "Absolument, n'hésitez pas à inclure des membres de votre famille.",
      paques: "Absolument, n'hésitez pas à inclure des membres de votre famille.",
    }
  },
  {
    title: "Combien coûte une séance photo ?",
    body: {
      grossesse: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
      bébé: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
      naissance: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
      baptême: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
      couple: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
      pro: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
      noel: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
      halloween: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
      paques: "Les tarifs peuvent varier en fonction de la durée de la séance, du lieu et du nombre de photos finales souhaitées. Je vous invite à consulter nos différents forfaits ou à me contacter directement pour obtenir un devis personnalisé.",
    }
  },
  {
    title: "Quand recevrai-je les photos ?",
    body: {
      grossesse: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
      bébé: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
      naissance: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
      baptême: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
      couple: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
      pro: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
      noel: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
      halloween: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
      paques: "Les photos finales sont généralement livrées dans un délai de 2 à 4 semaines après la séance. Vous recevrez une sélection de photos retouchées en haute résolution.",
    }
  },
  {
    title: "Puis-je obtenir des tirages ou un album photo ?",
    body: {
      grossesse: "Oui, nous proposons diverses options de tirages, de cadres et d'albums. Vous pouvez choisir parmi une gamme de produits pour mettre en valeur vos souvenirs de grossesse.",
      bébé: "",
      naissance: "",
      baptême: "",
      couple: "",
      pro: "",
      noel: "",
      halloween: "",
      paques: "",
    }
  },
  {
    title: "Y a-t-il des préparatifs spécifiques avant la séance ?",
    body: {
      grossesse: "Nous discuterons de vos attentes et je vous conseillerai sur la tenue et le style. Choisissez des plages horaires où vous vous sentez en forme pour une expérience optimale.",
      bébé: "Nous discuterons de vos préférences et je vous donnerai des conseils sur la tenue et le style. Assurez-vous que votre bébé soit bien reposé pour une expérience optimale.",
      naissance: "Nous discuterons de vos attentes et je vous conseillerai sur les tenues et accessoires à préparer pour le bébé.",
      baptême: "Nous planifions minutieusement la séance lors d'une rencontre préliminaire.",
      couple: "Nous discutons de vos attentes et je vous conseille sur la tenue et le style.",
      pro: "Nous discutons de vos besoins et je vous conseille pour garantir des images impactantes.",
      noel: "Assurez vous d'être en forme et avec une bonne hygiène.",
      halloween: "Nous discuterons avec vous de vos attentes et idées.",
      paques: "Nous discuterons avec vous de vos attentes et idées.",
    }
  },
]

export const Faq = ({ className, seanceType }: { className?: string, seanceType: string }) => {
  seanceType = seanceType.toLowerCase()
  return (
    <div
      className={cx(
        className,
        css({
          display: 'flex',
          alignItems: 'center',
          flexDir: 'column',
          gap: '20px',
        })
      )}
    >
      <Typography className={css({ color: '#19213D' })} as="h2">
        Questions fréquentes
      </Typography>
      <div
        className={css({
          w: '100%',
          '& > div:not(:last-child)': {
            borderBottom: 'none !important',
          },
        })}
      >
        {feedbacks.map((feedback, idx) => (
          <Accordeon title={feedback.title} body={feedback.body[seanceType] || "Information non disponible pour ce type de séance."} key={idx} />
        ))}
      </div>
    </div>
  )
}
