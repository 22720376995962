import React, { useEffect, useState } from 'react'
import { useSetOrder } from '../atoms/order.atom'
import { Typography } from '../components/photographie/Typography'
import { Package } from '@dup/products'
import { css } from '../../styled-system/css'
import { BackgroundsSelection as BackgroundsSelectionComponent } from '../components/photographie/BackgroundsSelection'
import { BackgroundsManager } from '../components/photographie/BackgroundsManager'
import { Button } from '../components/photographie/Button'
import { scrollToTop } from '../utils/scrollToTop'

export const BackgroundsSelection = (props: {
  pkg: Package
  back: () => void
  next: () => void
}) => {
  const { pkg, back, next } = props
  const setOrder = useSetOrder()
  const [selectedThemes, setSelectedThemes] = useState<string[]>([])

  const themes = pkg.backgrounds

  useEffect(() => {
    setOrder((prevValue) => {
      return { ...prevValue, backgrounds: selectedThemes }
    })
  }, [selectedThemes])

  useEffect(scrollToTop, [])

  return (
    <div
      className={css({
        display: 'flex',
        alignItems: 'center',
        gap: '3.125rem',
        flexWrap: 'wrap',
        flexDir: 'column',
        textAlign: 'center',
        lgDown: {
          gap: 0,
        }
      })}
    >
      <div
        className={css({
          flex: 0.5,
          display: 'flex',
          flexDir: 'column',
          gap: '1rem',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50%',
          lgDown: {
            width: '90%',
          }
        })}
      >
        <Typography
          className={css({ color: 'fonts.darkBlue', textAlign: 'center' })}
          as="h1"
        >
          Sélectionnez vos fonds
        </Typography>
        <BackgroundsSelectionComponent
          themes={themes}
          selectedThemes={selectedThemes}
          setSelectedThemes={setSelectedThemes}
        />
        {selectedThemes.length !== 0 ? (
          <BackgroundsManager
            themes={themes}
            selectedThemes={selectedThemes}
            setSelectedThemes={setSelectedThemes}
          />
        ) : null}

        <div
          className={css({
            display: 'flex',
            justifyContent: 'space-between',
            w: "100%",
            mt: '20px',
          })}
        >
          <Button
            onClick={back}
            variants={{ format: 'normal', visual: 'secondary' }}
          >
            Retour
          </Button>
          <Button onClick={next} variants={{ format: 'normal', visual: 'digit' }}>
            JE VALIDE
          </Button>
        </div>
      </div>
    </div >
  )
}
