import { useOrderValue } from '../../atoms/order.atom'
import { Typography } from '../../components/photographie/Typography'
import { BackgroundsSelection } from '../../views/BackgroundsSelection'
import { DateAndTimeSelection } from '../../views/DateAndTimeSelection'
import { OptionsSelection } from '../../views/OptionsSelection'
import { PackSelection } from '../../views/PackSelection'
import { Summary } from '../../views/Summary'
import { packages } from '@dup/products'
import React, { useEffect, useState } from 'react'
import { HeadFC, PageProps, navigate } from 'gatsby'
import { css } from '../../../styled-system/css'
import { Step, Stepper } from 'react-form-stepper'
import { useApi } from '../../hooks/useApi'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { Image } from '../../components/photographie/Image'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { PackageImages } from '../../components/photographie/PackageImages'
import { Hr } from '../../components/photographie/Hr'
import { Feedbacks_photos } from '../../components/photographie/Feedbacks'
import { Faq } from '../../components/photographie/Faq'

const OrderPage: React.FC<PageProps> = () => {
  if (typeof window === 'undefined') {
    return null
  }

  const breakpoints = useBreakpoint()
  const currentOrder = useOrderValue()
  const [currentView, setCurrentView] = useState(0)
  const pkg = packages.find(
    (_package) => _package.id === currentOrder.packageId
  )

  useEffect(() => {
    if (process.env.GATSBY_NO_ORDER === 'true') {
      navigate('/photographie/')
    }
  })

  useEffect(() => {
    if (!pkg) {
      navigate('/photographie/reservation')
    }
  }, [])

  if (!pkg) {
    navigate('/photographie/reservation')
    return null
  }

  const goToView = (view: number) => {
    if (currentView < view) {
      return
    }

    setCurrentView(view)
  }

  const views = [
    <PackSelection next={() => setCurrentView(1)} pkg={pkg} />,
    <OptionsSelection
      next={() => setCurrentView(2)}
      back={() => goToView(0)}
      pkg={pkg}
    />,
    <BackgroundsSelection
      next={() => setCurrentView(3)}
      back={() => goToView(1)}
      pkg={pkg}
    />,
    <DateAndTimeSelection
      onSelected={() => {
        if (currentView === views.length - 1) {
          return
        }
        setCurrentView(currentView + 1)
      }}
      back={() => goToView(2)}
    />,
    <Summary />,
  ]
  const renderView = () => {
    return views[currentView]
  }

  return (
    <HeaderAndFooterLayout>
      <div>
        {currentView !== 0 && !(breakpoints.md || breakpoints.xs) ? (
          <>
            <Image
              className={css({
                pos: 'absolute',
                rounded: '50%',
                opacity: '.1',
                width: '750px',
                height: '750px',
                objectFit: 'cover',
                right: '-400px',
                top: '300px',
                zIndex: '-1',
              })}
              relativePath={`packages/${pkg.id}/thumbnail.png`}
            />
            <Image
              className={css({
                pos: 'absolute',
                rounded: '50%',
                opacity: '.1',
                width: '500px',
                height: '500px',
                objectFit: 'cover',
                right: '-50px',
                top: '1300px',
                zIndex: '-1',
              })}
              relativePath={`packages/${pkg.id}/${pkg.images.at(0)?.name}`}
            />
          </>
        ) : null}

        <h2
          className={css({
            marginTop: '2rem',
            marginBottom: '2rem',
            fontSize: '2.25rem',
            fontWeight: '300',
            color: '#594A2E',
            textAlign: 'center',
            lgDown: {
              fontSize: '1rem',
            },
          })}
        >
          Vous avez choisi :{' '}
          <span
            className={css({
              fontWeight: '600',
              fontFamily: 'Assistant !important',
            })}
          >
            Séance photo {pkg.label.toLowerCase()}
          </span>
        </h2>
        {currentView === 0 ? (
          <div
            className={css({
              position: 'relative',
            })}
          >
            <Image
              className={css({
                width: '100%',
              })}
              relativePath={`packages/${pkg.id}/thumbnail.png`}
              alt="thumbnail"
            />
            <div
              className={css({
                position: 'absolute',
                top: '50%',
                left: 'calc(100% - 5rem)',
                color: 'white',
                width: '25rem',
                '@media (max-width: 960px)': {
                  opacity: 0,
                },
                '@media (max-width: 1200px)': {
                  width: '15rem',
                  left: 'calc(100% - 2.5rem)',
                },
                '@media (max-width: 1850px)': {
                  width: '20rem',
                  left: 'calc(100% - 3rem)',
                },
                lineHeight: '1.25',
                padding: '2rem',
                transform: 'translate(-100%, -50%)',
                opacity: 1,
                transition: 'opacity .5s',
                zIndex: 2,
              })}
            >
              <svg
                className={css({
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '3.75rem',
                  height: '3.75rem',
                  '@media (max-width: 1200px)': {
                    width: '2rem',
                    height: '2rem',
                  },
                  '@media (max-width: 1850px)': {
                    width: '2.875rem',
                    height: '2.875rem',
                  },
                })}
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_300_671)">
                  <path
                    d="M19.4737 38.9474C22.6711 38.9474 25.2632 36.3553 25.2632 33.1579C25.2632 29.9604 22.6711 27.3684 19.4737 27.3684C16.2762 27.3684 13.6842 29.9604 13.6842 33.1579C13.6842 36.3553 16.2762 38.9474 19.4737 38.9474Z"
                    fill="white"
                  />
                  <path
                    d="M19.4737 40C15.7011 40 12.6316 36.9305 12.6316 33.1579C12.6316 29.3853 15.7011 26.3158 19.4737 26.3158C23.2463 26.3158 26.3158 29.3853 26.3158 33.1579C26.3158 36.9305 23.2463 40 19.4737 40ZM19.4737 28.4211C16.8621 28.4211 14.7368 30.5463 14.7368 33.1579C14.7368 35.7695 16.8621 37.8947 19.4737 37.8947C22.0853 37.8947 24.2105 35.7695 24.2105 33.1579C24.2105 30.5463 22.0853 28.4211 19.4737 28.4211Z"
                    fill="white"
                  />
                  <path
                    d="M37.3684 38.9474C40.5659 38.9474 43.1579 36.3553 43.1579 33.1579C43.1579 29.9604 40.5659 27.3684 37.3684 27.3684C34.171 27.3684 31.5789 29.9604 31.5789 33.1579C31.5789 36.3553 34.171 38.9474 37.3684 38.9474Z"
                    fill="white"
                  />
                  <path
                    d="M37.3684 40C33.5958 40 30.5263 36.9305 30.5263 33.1579C30.5263 29.3853 33.5958 26.3158 37.3684 26.3158C41.1411 26.3158 44.2105 29.3853 44.2105 33.1579C44.2105 36.9305 41.1411 40 37.3684 40ZM37.3684 28.4211C34.7568 28.4211 32.6316 30.5463 32.6316 33.1579C32.6316 35.7695 34.7568 37.8947 37.3684 37.8947C39.98 37.8947 42.1053 35.7695 42.1053 33.1579C42.1053 30.5463 39.98 28.4211 37.3684 28.4211Z"
                    fill="white"
                  />
                  <path
                    d="M13.6842 33.6842C13.1021 33.6842 12.6316 33.2137 12.6316 32.6316C12.6316 24.5053 19.2421 17.8948 27.3684 17.8948C27.9505 17.8948 28.4211 18.3653 28.4211 18.9474C28.4211 19.5295 27.9505 20 27.3684 20C20.4032 20 14.7368 25.6664 14.7368 32.6316C14.7368 33.2137 14.2663 33.6842 13.6842 33.6842Z"
                    fill="white"
                  />
                  <path
                    d="M31.5789 33.6842C30.9968 33.6842 30.5263 33.2137 30.5263 32.6316C30.5263 24.5053 37.1368 17.8948 45.2632 17.8948C45.8453 17.8948 46.3158 18.3653 46.3158 18.9474C46.3158 19.5295 45.8453 20 45.2632 20C38.2979 20 32.6316 25.6664 32.6316 32.6316C32.6316 33.2137 32.1611 33.6842 31.5789 33.6842Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_300_671">
                    <rect width="60" height="60" fill="white" />
                  </clipPath>
                </defs>
              </svg>

              <p
                className={css({
                  color: 'white',
                  fontFamily: 'Alex Brush !important',
                  fontSize: '2.5rem',
                  '@media (max-width: 1200px)': {
                    fontSize: '1.5rem',
                  },
                  '@media (max-width: 1850px)': {
                    fontSize: '2rem',
                  },
                  fontWeight: '400',
                  textAlign: 'center',
                })}
              >
                En chaque battement, l&apos;avenir murmure, tissant dans le
                silence de l&apos;être, l&apos;aube d'une nouvelle vie.
              </p>
              <svg
                className={css({
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  width: '3.75rem',
                  height: '3.75rem',
                  '@media (max-width: 1200px)': {
                    width: '2rem',
                    height: '2rem',
                  },
                  '@media (max-width: 1850px)': {
                    width: '2.875rem',
                    height: '2.875rem',
                  },
                })}
                width="60"
                height="60"
                viewBox="0 0 60 60"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_300_681)">
                  <path
                    d="M40.5263 38.9474C37.3289 38.9474 34.7368 36.3553 34.7368 33.1579C34.7368 29.9604 37.3289 27.3684 40.5263 27.3684C43.7238 27.3684 46.3158 29.9604 46.3158 33.1579C46.3158 36.3553 43.7238 38.9474 40.5263 38.9474Z"
                    fill="white"
                  />
                  <path
                    d="M40.5263 40C44.299 40 47.3684 36.9305 47.3684 33.1579C47.3684 29.3853 44.299 26.3158 40.5263 26.3158C36.7537 26.3158 33.6842 29.3853 33.6842 33.1579C33.6842 36.9305 36.7537 40 40.5263 40ZM40.5263 28.4211C43.1379 28.4211 45.2632 30.5463 45.2632 33.1579C45.2632 35.7695 43.1379 37.8947 40.5263 37.8947C37.9147 37.8947 35.7895 35.7695 35.7895 33.1579C35.7895 30.5463 37.9147 28.4211 40.5263 28.4211Z"
                    fill="white"
                  />
                  <path
                    d="M22.6316 38.9474C19.4341 38.9474 16.8421 36.3553 16.8421 33.1579C16.8421 29.9604 19.4341 27.3684 22.6316 27.3684C25.829 27.3684 28.4211 29.9604 28.4211 33.1579C28.4211 36.3553 25.829 38.9474 22.6316 38.9474Z"
                    fill="white"
                  />
                  <path
                    d="M22.6316 40C26.4042 40 29.4737 36.9305 29.4737 33.1579C29.4737 29.3853 26.4042 26.3158 22.6316 26.3158C18.8589 26.3158 15.7895 29.3853 15.7895 33.1579C15.7895 36.9305 18.8589 40 22.6316 40ZM22.6316 28.4211C25.2432 28.4211 27.3684 30.5463 27.3684 33.1579C27.3684 35.7695 25.2432 37.8947 22.6316 37.8947C20.02 37.8947 17.8947 35.7695 17.8947 33.1579C17.8947 30.5463 20.02 28.4211 22.6316 28.4211Z"
                    fill="white"
                  />
                  <path
                    d="M46.3158 33.6842C46.8979 33.6842 47.3684 33.2137 47.3684 32.6316C47.3684 24.5053 40.7579 17.8948 32.6316 17.8948C32.0495 17.8948 31.5789 18.3653 31.5789 18.9474C31.5789 19.5295 32.0495 20 32.6316 20C39.5968 20 45.2632 25.6664 45.2632 32.6316C45.2632 33.2137 45.7337 33.6842 46.3158 33.6842Z"
                    fill="white"
                  />
                  <path
                    d="M28.421 33.6842C29.0032 33.6842 29.4737 33.2137 29.4737 32.6316C29.4737 24.5053 22.8632 17.8948 14.7368 17.8948C14.1547 17.8948 13.6842 18.3653 13.6842 18.9474C13.6842 19.5295 14.1547 20 14.7368 20C21.7021 20 27.3684 25.6664 27.3684 32.6316C27.3684 33.2137 27.8389 33.6842 28.421 33.6842Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_300_681">
                    <rect
                      width="60"
                      height="60"
                      fill="white"
                      transform="matrix(-1 0 0 1 60 0)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        ) : null}
        <Stepper
          className={css({
            margin: '0 20rem',
            lgDown: { margin: '0', padding: '1rem !' },
          })}
          activeStep={currentView}
          connectorStateColors={true}
          connectorStyleConfig={{
            activeColor: '#000',
            completedColor: '#000',
            size: !breakpoints.md && !breakpoints.xs ? '.2rem' : '.15rem',
            disabledColor: 'rgb(224, 224, 224)',
            style: 'solid',
          }}
          styleConfig={{
            activeBgColor: '#000',
            activeTextColor: '#F2D98D',
            borderRadius: '50%',
            circleFontSize:
              !breakpoints.md && !breakpoints.xs ? '1rem' : '.75rem',
            completedBgColor: '#000',
            completedTextColor: '#F2D98D',
            fontWeight: '400',
            inactiveBgColor: 'rgb(224, 224, 224)',
            inactiveTextColor: '#6D758F',
            size: !breakpoints.md && !breakpoints.xs ? '3rem' : '2rem',
            labelFontSize:
              !breakpoints.md && !breakpoints.xs ? '1rem' : '.5rem',
          }}
        >
          <Step onClick={() => goToView(0)} label="Choix du pack" />
          <Step onClick={() => goToView(1)} label="Choix des options" />
          <Step onClick={() => goToView(2)} label="Choix des fonds" />
          <Step onClick={() => goToView(3)} label="Choix de la date" />
          <Step label="Résumé et paiment" />
        </Stepper>

        <div
          className={css({
            marginTop: currentView === 0 ? '1.25rem' : '5rem',
            lgDown: {
              marginTop: currentView === 0 ? '.25rem' : '5rem',
            },
          })}
        >
          {renderView()}
        </div>

        <div
          className={css({
            display: 'flex',
            flexDir: 'column',
            alignItems: 'center',
            width: '100%',
          })}
        >
          <PackageImages
            className={css({ m: '2.5rem 0', w: '100%' })}
            id={pkg.id}
            amount={4}
          />

          {currentView !== views.length - 1 ? (
            <>
              <Hr />

              <div
                className={css({
                  width: '90%',
                  margin: '0 auto 0',
                })}
              >
                <div
                  className={css({
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '1.875rem',
                    padding: '1.25rem',
                    width: '100%',
                  })}
                >
                  <Typography
                    className={css({
                      color: '#19213D',
                      fontWeight: '500',
                      textAlign: 'center',
                      fontSize: '2rem',
                      '@media (max-width: 768px)': {
                        fontSize: '1.5rem',
                      },
                    })}
                    as="h1"
                  >
                    Ils ont aimé leur séance photo {pkg.label.toLowerCase()}{' '}
                    avec Digit Universe
                  </Typography>

                  <Feedbacks_photos title={''} seanceType={pkg.label} />
                </div>
              </div>

              <PackageImages
                special={true}
                className={css({
                  m: '2.5rem 0',
                  p: '0 0 1.25rem 0 !important',
                  lgDown: { p: '0 0 .35rem 0 !' },
                })}
                id={pkg.id}
                amount={8}
                startAt={4}
              />
            </>
          ) : null}

          {currentView == 0 ? (
            <>
              <Hr />
              <Faq
                seanceType={pkg.label}
                className={css({
                  marginBottom: '5rem',
                  marginLeft: '5rem',
                  marginRight: '5rem',
                })}
              />
            </>
          ) : null}
        </div>
      </div>
    </HeaderAndFooterLayout>
  )
}

export default OrderPage

export const Head: HeadFC = () => <title>Réservation | Digit Universe</title>
